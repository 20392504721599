import { Dispatch, SetStateAction, useEffect, useState } from "react";
import TicketStatus from "../../enums/TicketStatus";
import { Row, Col, Spinner, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import StatCard from "../../components/cards/statCard/StatCard";
import Status from "../../enums/Status";
import ITicket from "../../models/ITicket";
import ticketService from "../../services/helpdesk/TicketService";
import IAssignee from "../../models/IAssignee";
import PageHeader from "../../components/pageHeader/PageHeader";

const SelectedTicketStatus = (path: string) => {
    switch (path) {
        case "/helpdesk/tickets/onhold":
            return TicketStatus.ON_HOLD;
        default:
            return TicketStatus.OPEN;
    }
}

export default function Reporting() {
    const { t } = useTranslation();
    
    const location = useLocation();
    const path = location.pathname;

    const [tickets, setTickets] = useState<ITicket[]>([]);
    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [assigneeStats, setAssigneeStats] = useState<{ assignee: IAssignee, count: number }[]>([]);
    const [priorityCounts, setPriorityCounts] = useState<{ low: number; medium: number; high: number }>({
        low: 0,
        medium: 0,
        high: 0,
    });
    
    const [overdueCount, setOverdueCount] = useState<number>(0);

    useEffect(() => getData(path, setTickets, setStatus), [path]);

    useEffect(() => {
        const ticketAssignees = tickets.map(ticket => ticket.assignee).filter(Boolean);
    
        // Count how many tickets each assignee has
        const countsMap: { [assigneeId: string]: { assignee: typeof ticketAssignees[0], count: number } } = {};
    
        ticketAssignees.forEach(assignee => {
            const assigneeEmailAddress = assignee!.emailAddress;
            if (!countsMap[assigneeEmailAddress]) {
                countsMap[assigneeEmailAddress] = { assignee: assignee!, count: 0 };
            }
            countsMap[assigneeEmailAddress].count += 1;
        });
    
        // Convert to array of stats
        const stats = Object.values(countsMap).sort((a, b) => {
            return a.assignee.name.localeCompare(b.assignee.name);
        });

        const counts: { low: number; medium: number; high: number } = { low: 0, medium: 0, high: 0 };
        let overdue = 0;
        const now = new Date();
    
        tickets.forEach(ticket => {
            const priorityName = ticket.priority?.name?.toLowerCase() as 'low' | 'medium' | 'high';
            
            if (priorityName && counts[priorityName] !== undefined) {
                counts[priorityName] += 1;
            }
    
            if (ticket.targetCompletionTime && new Date(ticket.targetCompletionTime) < now) {
                overdue += 1;
            }
        });
    
        setPriorityCounts(counts);
        setOverdueCount(overdue);
        setAssigneeStats(stats);
    }, [tickets]);

    if (status === Status.LOADING) {
        return (
            <>
                <div className="text-center">
                    <Spinner animation="border" variant="dark" />
                </div>
            </>
        );
    }

    if (status === Status.LOAD_ERROR) {
        return (
            <>
                <Alert variant="danger">{t("helpdesk.exceptions.loadTickets")}</Alert>
            </>
        );
    }

    return (
        <>
         <PageHeader title={t("administration.reporting")} subtitle={t("administration.titles.hd")} />
         <h2>Open Tickets by Assignee</h2>
            <Row>
                {assigneeStats.map(({ assignee, count }) => (
                    <Col xs={12} md={6} lg={4} key={assignee.emailAddress}>
                    <Link to={`/administration/tickets?assignee=${encodeURIComponent(assignee.name)}`}>
                        <StatCard title={assignee.name} value={count} icon="List" />
                    </Link>
                </Col>
                ))}
            </Row>

            <h2>Tickets by Priority</h2>
            <Row>
            <Col xs={12} md={6} lg={4}>
                <StatCard title="Low Priority" value={priorityCounts.low} icon="Dash" />
            </Col>
            <Col xs={12} md={6} lg={4}>
                <StatCard title="Medium Priority" value={priorityCounts.medium} icon="DiamondHalf" />
            </Col>
            <Col xs={12} md={6} lg={4}>
                <StatCard title="High Priority" value={priorityCounts.high} icon="ExclamationTriangle" />
            </Col>

            <h2>Overdue Tickets</h2>
            <Col xs={12} md={6} lg={4}>
                <Link to={`/administration/tickets?overdue=${encodeURIComponent(true)}`}>
                    <StatCard title="Overdue Tickets" value={overdueCount} icon="Calendar" />
                </Link>
            </Col>
        </Row>
        </>
    );
}

function getData(
    path: string,
    setTickets: Dispatch<SetStateAction<ITicket[]>>,
    setStatus: Dispatch<SetStateAction<Status>>
) {
    setStatus(Status.LOADING);
    
    try {

        (async () => {
            //const account = await accountService.getAccount();
            //const roles = accountService.getRoles();

            let tickets = await ticketService.getAllTickets();

            setTickets(tickets);
            
            setStatus(Status.LOAD_SUCCESS);
        })();
    } catch {
        setStatus(Status.LOAD_ERROR)
    }
}